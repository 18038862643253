import {FaCircleCheck} from "react-icons/fa6";
import React from "react";

interface ISourceAction {
  active: boolean,
  setActive: React.Dispatch<React.SetStateAction<boolean>>,
  ensureOneSelected:(source?:boolean)=>boolean
  title: string,
  icon_url: string
}

const SourceAction = (props: ISourceAction) => {
  return(
  <div className={"data-source" + (props.active ? " data-source--active" : " data-source--inactive")}
    onClick={() => {
      if(props.ensureOneSelected(props.active)){
        props.setActive(!props.active)
      const key = `source-${props.title}`
      const value = JSON.stringify({active: !props.active});
      localStorage.setItem(key, value)
      }
      
      }}>
    <div className={"w-full p-4 aspect-square rounded-md bg-contain bg-no-repeat bg-center"}>
      <div className={"w-full h-full bg-contain bg-no-repeat bg-center"} style={{
        backgroundImage: "url(" + props.icon_url + ")"
      }}/>
    </div>
    <div className={"p-1 font-bold"}>{props.title}</div>
    <div className={"data-source__check"}><FaCircleCheck size={"24px"}/></div>
  </div>
  )
}

export default SourceAction;

/* localStorage.setItem("", "")*/
/*localStorage.setItem(props.toString(), props.ensureOneSelected(props.active).toString())*/


