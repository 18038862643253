import React, { useContext } from 'react'
import {FaGear} from "react-icons/fa6";
import MathMexContext from './MathMexContext';

interface ISettingsGear {
    inSettings: boolean;
    setInSettings: React.Dispatch<React.SetStateAction<boolean>>;

  }

function SettingsGear() {
  const context=useContext(MathMexContext)
  return (
    <div 
        className={"settings-button" + ((context?.inSettings) ? " settings-button--active" : "")} 
        onClick={() => {
          context?.setInSettings(!context?.inSettings);
        }}
    >
        <FaGear size={"1.5rem"} className={"settings-icon"}/>
    </div>
  )
}

export default SettingsGear