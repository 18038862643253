import React from 'react'
import {FaQuoteLeft, FaQuoteRight, FaRegThumbsDown, FaRegThumbsUp} from "react-icons/fa6";



interface IFeedback{
    feedback:number,
    clickThumbsUp:()=>void
    clickThumbsDown:()=>void
    style?:any
    className?:string
    size?:string
    leftStyle?:any
    rightStyle?:any
}

function Feedback(props:IFeedback) {
  return (
    <div className={props.className} style={props.style}>
          <FaRegThumbsUp
            style={props.leftStyle}
            size={"32px"}
            className={'mathmex-response--thumbsup' + (props.feedback === 1 ? ' mathmex-response--thumbsup__active' : '')}
            onClick={props.clickThumbsUp}
          />
          <FaRegThumbsDown
            style={props.rightStyle}
            size={"32px"}
            className={'mathmex-response--thumbsdown' + (props.feedback === -1 ? ' mathmex-response--thumbsdown__active' : '')}
            onClick={props.clickThumbsDown}
          />
    </div>
  )
}

export default Feedback