import UserSearch, {IUserSearch} from "./UserSearch";
import MexResponse, {IMexResponse} from "./MexResponse";
import NextResultButton from "./NextResultButton";
import { useState,useEffect, useContext, useRef } from "react";
import MathMexContext from "./MathMexContext";

export interface IQueryBlock {
  search: IUserSearch,
  responses: Array<IMexResponse>,
  showNextResult: boolean
  // key is optional here as it allows the map func to correctly place the components
  key?:string
  id:string
  numLoadedResults:number
}

const QueryBlock = (props: IQueryBlock) => {
  const context= useContext(MathMexContext)
  const [resultIndex,setResultIndex]=useState(props.numLoadedResults)
  const [showNextResult,setShowNextResult]=useState(props.showNextResult)
  const blockRef=useRef<IQueryBlock>()
  useEffect(() => {
    // store a reference to current block so we can set showNextResult to false
    let thisBlock=blockRef.current

  

    if (resultIndex===props.responses.length-1){
      setShowNextResult(false)
      // if thisBlock is defined and we have exhausted responses we need to set the showNextResult to false in the top level component
      if(thisBlock){
        (thisBlock as IQueryBlock).showNextResult=false
      }
    }
  }, [resultIndex]);

  useEffect(() => {


  }, [showNextResult]);
  
  const loadNextResult=() => {
    setResultIndex(resultIndex+1)
    // Moved outside of useEffect because it was triggering upon render
    context?.queryBlocks.forEach(block=>{
      if(block.id===props.id){
        block.numLoadedResults+=1 
        blockRef.current=block
      }
    })

  }
  
  return <div className={"query-block-wrapper"}>
    <UserSearch query={props.search.query} date={props.search.date}/>
    <div>
      {props.responses.filter((_, index) => index < resultIndex+1).map(resp => <MexResponse
        relevance={resp.relevance}
        link_href={resp.link_href}
        title={resp.title} 
        body={resp.body}
        image_path={resp.image_path}
        feedback={resp.feedback}
        respIndex={resp.respIndex}
        id={props.id}
      />)}
    </div>
    <div className={"main-container"}>
      <div className={"horizontal-rule"}>
        {showNextResult && <NextResultButton onClick={loadNextResult}/>}
      </div>
    </div>
  </div>
}

export default QueryBlock