import React,{useContext} from 'react'
import {queryFunction} from './Header'
import { isMobile } from 'react-device-detect'
import { FaArrowRotateRight } from "react-icons/fa6";

import UserSearch from './UserSearch';
import MathMexContext from './MathMexContext';

interface ITempQueryBlock{
    status:number
    query:string
}
 
function TempQueryBlock(props:ITempQueryBlock) {
  const context=useContext(MathMexContext)
  const resendQuery=(query:string) => {
    if(context){
        queryFunction(query,context)
    }
  }

  if(props.status===1){
        return (
            <>
            <UserSearch query={props.query} date={new Date()}/>
            <div className={' flex flex-row'}>
                <div className={'mathmex-response__loading'}>
                    
                </div>
                {isMobile?undefined:
                <div className={'side-container'}>
                    {/* Used to hold the same amount of space as the Feedback component */}
                </div>
                }
            </div>
            </>
        )
  }
    // Query failed with value of two
    return (
        <>
        <UserSearch query={props.query} date={new Date()}/>
        <div className={'mathmex-response__failure'}>
            
            <div className={'flex items-center flex-col mathmex-response__failure--content'}>
                <b>Query Failed</b>
                <FaArrowRotateRight 
                    className={'mathmex-response__failure--retry'}
                    onClick={() => {
                        resendQuery(props.query)
                    }}
                />
                <span 
                    className={'text-sm underline cursor-pointer underline-offset-4'}
                    onClick={() => {
                        resendQuery(props.query)
                    }}
                >
                    Click to re-send the query
                </span>
            </div>
        </div>
        </>
    )

}

export default TempQueryBlock