import { FaPlus } from "react-icons/fa6";


type NextResultButtonClick = {
  onClick: () => void;
}


const NextResultButton = ({ onClick }:NextResultButtonClick ) => {
  return (
  <div className={"next-result"} onClick={onClick}>
    <FaPlus className={"mr-2"}/> Next Result
  </div>
  )
}

export default NextResultButton;