import React,{useState,useEffect, useRef, useContext} from 'react'
import useMutationObserver from "@rooks/use-mutation-observer"
import {FaPlus,FaMagnifyingGlass,FaTrashCan,FaArrowTurnUp} from "react-icons/fa6"

import "mathlive";
import {MathfieldElement} from "mathlive";
import MathMexContext, { IMathMexContext } from './MathMexContext';
import { text } from 'stream/consumers';








declare global {
    namespace JSX {
      interface IntrinsicElements {
        'math-field': React.DetailedHTMLProps<React.HTMLAttributes<MathfieldElement>, MathfieldElement>;
      }
}
    
}

interface IMexInputProps{
    queryFunction:(query:string,context:IMathMexContext)=>void
}


interface IIndexableMathFieldMetadata{
    [key:string | number]:{
        symbol:string,
        value:string,
        caretPositionX?:number 
        caretPositionY?:number 
    }
}

export interface IMexInputData{

    indexableMathFieldValues:IIndexableMathFieldMetadata,
    currentMathFieldIndex:number,
    mexInputInnerHtml:string,
}


const pasteHtmlAtCaret = (html:any) => {
    let range;
    const parser = new DOMParser();
    // Parse the html string into a document
    const el = parser.parseFromString(html, "text/html").body;
    
    if (window.getSelection) {
        // IE9 and non-IE
        const sel = window.getSelection();
       
        // check if the user is focused on the text input, we dont want them to use this paste function anywhere else
        if(document.activeElement?.id!=='mexContentEditable'){
            return false
        }
        // I need to prevent nesting!
        if (sel?.getRangeAt && sel?.rangeCount) {
            range = sel.getRangeAt(0);
            range.deleteContents();

            const frag = document.createDocumentFragment();
            
            let node, lastNode;
            while ((node = el.firstChild)) {
                lastNode = frag.appendChild(node);
            }
           
            range.insertNode(frag);
            // Preserve the selection
            if (lastNode) {
                range = range.cloneRange();
                range.setStartAfter(lastNode);
                range.collapse(true);
                sel.removeAllRanges();
                sel.addRange(range);
            }
        }
    }
    return true
};

const generateSymbolString = (length:number) => {
    const baseOffset=97
    return `${String.fromCharCode(baseOffset+length%26)}${'`'.repeat(Math.floor(length/26))}(x)`
};

const constructSymbolElement=(tag:string,css:string,classes:string[],contentEditable:boolean,innerText:string ,id?:string) => {
    return `<${tag} ${id?`id=${id}`:""} class="${classes.join(' ')}" contentEditable=${contentEditable} style="${css}">${innerText}</${tag}>`
}

const symbolToIndex = (symbol:string)=>{
    const baseOffset=97
    const baseLength=4
    return symbol.charCodeAt(0)-baseOffset + 25*(symbol.length-baseLength) + symbol.length-baseLength

}

/*  TODO (Features that would improve the MexInput but aren't required)
    1. Upon paste turn properly delimited LaTeX expressions will be turned into symbols with corresponding math fields
    2. Provide proper ctrl-z functionality by deleting entrys from the indexableMathFieldValuesState but store them in the indexableMathFieldValuesRef
    3. Upon submit interpolate the LaTeX strings into MathJax elements then display them in the MexInput. When the user focuses the component all the text will dissapear
       but the text wont truly be gone until they input the first character.
*/
function MexInput(props:IMexInputProps) {

const context = useContext(MathMexContext)

const [indexableMathFieldValuesState,setIndexableMathFieldValuesState] =useState<IIndexableMathFieldMetadata>(context?.mexInputData.current?.indexableMathFieldValues?context?.mexInputData.current.indexableMathFieldValues:{})
const indexableMathFieldValuesRef=useRef<IIndexableMathFieldMetadata>(context?.mexInputData.current?.indexableMathFieldValues?context?.mexInputData.current.indexableMathFieldValues:{})
const newMathFieldIndices=useRef<number[]>([])
const textAreaRef=useRef<HTMLDivElement>(null)
const currentMathFieldIndex=useRef<number>(0)
const focusFlag=useRef<boolean>(false)




const updateMexInputData=()=>{
    // checking if everything is defined and update the entire global object
    if(context?.mexInputData && indexableMathFieldValuesRef.current && textAreaRef.current){
        context.mexInputData.current!.mexInputInnerHtml=textAreaRef.current.innerHTML
        context.mexInputData.current!.indexableMathFieldValues=indexableMathFieldValuesRef.current
        context.mexInputData.current!.currentMathFieldIndex=currentMathFieldIndex.current
    }
}

useMutationObserver(textAreaRef, (records:MutationRecord[]) => {
    console.log(records)
    records.forEach((record:MutationRecord) => {
        record.removedNodes.forEach((node:any) => {
            //solution feels a little weak but will work for now
            // I decided I can assume that the node being of type <b> is a strong enough indicator
            if(node.nodeName==="B"){
    
                // temporarily disabled as it can cause repetition in the symbols
                delete indexableMathFieldValuesRef.current[node.id]
                focusFlag.current=false
                updateMexInputData()
                setIndexableMathFieldValuesState({...indexableMathFieldValuesRef.current})
            }
        })
    })
});



const handleMathFieldSwitch=(event:Event,index:number,) => {
    currentMathFieldIndex.current=index;
    const mathFieldBeingSwitchedTo=document.getElementById(`mf-${index}`);
   
    (mathFieldBeingSwitchedTo as MathfieldElement).setCaretPoint(indexableMathFieldValuesRef.current[index].caretPositionX || 0,indexableMathFieldValuesRef.current[index].caretPositionY ||0);
    mathFieldBeingSwitchedTo?.focus()
    
}


const moveCaretToSpecificIndex=(symbol:string)=>{
    
    if (textAreaRef && textAreaRef.current) {
        const sel = window.getSelection();
        const range = document.createRange();
        let bIndice=0
        //special case that causes errors when looking for the next text node
        if(textAreaRef.current.childNodes.length===1){
            return
        }

        for(var i=0;i<textAreaRef.current.childNodes.length;i++){
            if(textAreaRef.current.childNodes[i].nodeName==="B" && textAreaRef.current.childNodes[i].childNodes[0].textContent===symbol){
                        bIndice=i
                        break
            }
        }
        // Issue was happening because I wasn't focusing on the right one, rather the one after the <b> tag
        range.setStart(textAreaRef.current.childNodes[bIndice+1],1);
        range.setEnd(textAreaRef.current.childNodes[bIndice+1],1);
        range.collapse(false)
        sel?.removeAllRanges();
        sel?.addRange(range)
        textAreaRef.current.focus()
    }
}

const handleSubmit=()=>{
    if(textAreaRef.current?.innerHTML!==undefined){    
        const delimiter='$'
        const parser = new DOMParser()
        const virtualTextArea=parser.parseFromString(textAreaRef.current.innerHTML,"text/html")
        const symbolElements=virtualTextArea.getElementsByTagName('b')
        for(var i=0;i<symbolElements.length;i++){
            symbolElements[i].innerText=indexableMathFieldValuesRef.current[symbolToIndex(symbolElements[i].innerText)].value
            // If the string is not delimited, apply delimiters
            if(symbolElements[i].innerText[0]!==delimiter && symbolElements[i].innerText[0]!==delimiter){
                symbolElements[i].innerText=`${delimiter}${symbolElements[i].innerText}${delimiter}`
            }
            
        }
        console.log(virtualTextArea.body.innerText)
        if(context && virtualTextArea.body.innerText){
            props.queryFunction(virtualTextArea.body.innerText,context)
        }
        textAreaRef.current.blur()
    }
}

const handleGarbage=()=>{
    indexableMathFieldValuesRef.current={}
    setIndexableMathFieldValuesState({})
    textAreaRef.current!.innerHTML=''
    updateMexInputData()
}

const handleMathFieldAddition=()=>{
    let size=calculateAvailableIndex()

   
    
    const symbolString=generateSymbolString(size)
    
   
    // I expanded this function as it's length has me to miss errors in the past
    const pasteResult:boolean=pasteHtmlAtCaret(constructSymbolElement(
        'b',
        '',
        ['equation-symbol']
        ,false,
        symbolString,
        String(size))+'&nbsp;'
        
    )
    
    if(pasteResult){

        currentMathFieldIndex.current=size

        indexableMathFieldValuesRef.current[size]={
            symbol:symbolString,
            value:'',
        }

        const symbolElement=document.getElementById(String(size))

        symbolElement?.addEventListener('click',(e) => {
            handleMathFieldSwitch(e,size)
        })
        
        //active math field could be replaced by this
        updateMexInputData()
        setIndexableMathFieldValuesState({...indexableMathFieldValuesRef.current})
    }
}

const calculateAvailableIndex=()=>{
    const keys= Object.keys(indexableMathFieldValuesRef.current)
    for(var i=0;i<keys.length;i++){
      if(keys[i]!==String(i)){
        return i
      }
    }
    return keys.length
}

const handlePaste=(event:any)=>{
    const delimiterRegex:RegExp=new RegExp(/\$([^\$]+)\$|\$\$([^\$]+)\$\$|\\\(([^\\]+)\\\)/gm)
    let text:string=event.clipboardData.getData('text')
    const constructedElements:number[]=[]
    if(text){
       
        text=text.replaceAll(delimiterRegex,(matchedText) => {
            const newIndex =calculateAvailableIndex()
            constructedElements.push(newIndex)
            const symbolString=generateSymbolString(newIndex)

            indexableMathFieldValuesRef.current[newIndex]={
                symbol:symbolString,
                value:matchedText,
            }

            return constructSymbolElement(
                'b',
                '',
                ['equation-symbol']
                ,false,
                symbolString,
                String(newIndex)
            )+'\u200B'
        })
        
        const pasteResult:boolean=pasteHtmlAtCaret(text)
        if(pasteResult){
            constructedElements.forEach((elementId)=>{
            const symbolElement=document.getElementById(String(elementId))

            symbolElement?.addEventListener('click',(e) => {
                handleMathFieldSwitch(e,elementId)
                })
            })
            focusFlag.current=false
            newMathFieldIndices.current=constructedElements
            updateMexInputData()
            setIndexableMathFieldValuesState({...indexableMathFieldValuesRef.current})
        }
       
    }
}




useEffect(() => {

    // if we are focusing (state was altered in handleMathFieldAddition) we add the newest math-field to newMathFieldIndices
    if(focusFlag.current){
        newMathFieldIndices.current.push(currentMathFieldIndex.current)
    }

    newMathFieldIndices.current.forEach((index:number)=>{
        const newMathField=document.getElementById(`mf-${index}`)
        newMathField?.addEventListener('selection-change',(evt:Event) => {
        const caretPoint=(newMathField as MathfieldElement).caretPoint
        if(caretPoint){
            indexableMathFieldValuesRef.current[index].caretPositionX=caretPoint.x
            indexableMathFieldValuesRef.current[index].caretPositionY=caretPoint.y
        }
        });
    });
    // Reset this array as it will only be set during a paste event
    newMathFieldIndices.current=[]

    // After applying all the event listeners we check to see if the app wants to focus the new mathfield
    if(!focusFlag.current){
        focusFlag.current=true
        return
    }

    // If we wan to focus grab the element, set caret point and focus
    const currentMathField:any=document.getElementById(`mf-${currentMathFieldIndex.current}`);
    (currentMathField as MathfieldElement)?.setCaretPoint((indexableMathFieldValuesRef.current[currentMathFieldIndex.current].caretPositionX || 0),indexableMathFieldValuesRef.current[currentMathFieldIndex.current].caretPositionY || 0);
    currentMathField?.focus()
    
},[indexableMathFieldValuesState])

useEffect(() => {
    if(context?.mexInputData.current?.mexInputInnerHtml && textAreaRef.current){
        textAreaRef.current.innerHTML=context?.mexInputData.current.mexInputInnerHtml
        // attach event listeners 
        Object.keys(indexableMathFieldValuesRef.current).forEach((key:string) => {
            
            const symbolElement=document.getElementById(key)

            symbolElement?.addEventListener('click',(e) => {
                    handleMathFieldSwitch(e,Number(key))
                })
            
            const currentMathField=document.getElementById(`mf-${key}`)
            currentMathField?.addEventListener('selection-change',(evt:Event) => {
                const caretPoint=(currentMathField as MathfieldElement).caretPoint
                if(caretPoint){
                    indexableMathFieldValuesRef.current[key].caretPositionX=caretPoint.x
                    indexableMathFieldValuesRef.current[key].caretPositionY=caretPoint.y
                }
            })
        })
    }
    focusFlag.current=true
},[])   

  return (
    <div className='mex-input-wrapper'>
        <div className='mexinput-container rounded-md'>
        
            <div 
                className='mex-textarea-wrapper w-full p-3'
                >
                <div
                    ref={textAreaRef}
                    id='mexContentEditable'
                    contentEditable={true}
                    className="w-full p-4 resize-none mex-textarea" 
                    data-placeholder={'Search for mathematical concepts!'}
                    onChange={(evt) => {
                    
                    }}
                    onKeyDown={(evt) => {
                        updateMexInputData()
                        if (evt.ctrlKey && evt.key === 'z') {
                            evt.preventDefault();
                          }
                        else if(evt.key==='Enter'){
                            evt.preventDefault();
                            handleSubmit()
                        }
                    }}
                    onInput={() => {
                        updateMexInputData()
                    }}

                    onPaste={(evt) => {
                        evt.preventDefault()
                        handlePaste(evt)
                       
                    }}
                > 
            
                
                </div>
            
                <div className='flex flex-row justify-end'>
                    <FaPlus
                        className="mex-textarea-button" 
                        size={'1.3em'}
                        onClick={() => 
                            handleMathFieldAddition()
                        }
                        onMouseDown={(evt) => {
                            evt.preventDefault()
                        }}
                    />
                    <FaTrashCan
                        className="mex-textarea-button mex-textarea-trash" 
                        size={'1.3em'}
                        onClick={() => 
                           handleGarbage()
                        }
                        onMouseDown={(e) => {
                            e.preventDefault()
                        }}
                    />
                    <FaMagnifyingGlass 
                        className="mex-textarea-button" 
                        size={'1.3em'}
                        onClick={() => 
                           handleSubmit() 
                        }
                    />
                </div>
            </div>
        </div>
        {!!Object.keys(indexableMathFieldValuesState).length && 
        <div className={'multi-mathfield-container'}>
          
                
                {Object.entries(indexableMathFieldValuesState).map(entry=>{
                    console.log(indexableMathFieldValuesState)
                    return (
                        <div className='flex flex-row items-center mf-container'>
                        
                        <b
                        style={{
                            cursor:'default'
                        }}
                        className='equation-symbol'
                        >
                            {entry[1]?.symbol} 
                        </b>
                       
                        <math-field
                            key={entry[0]} 
                            id={`mf-${entry[0]}`} 
                            className=""
                            onInput={(evt) => {
                                const mathField=evt.target as MathfieldElement
                                if(entry[0]){
                                    indexableMathFieldValuesRef.current[entry[0]].value =mathField.value
                                }
                            }}
                        >
                       
                            {entry[1]?.value}
                            
                        </math-field>
                        <FaArrowTurnUp
                            className='cursor-pointer'
                            size={'1.5em'}
                            style={{
                                minWidth:'1.5em',
                                marginRight:'10px'
                            }}
                            // This is a little insane but I have to do it because i'm storing the style as an inline string
                            // perhaps this will change in the future by making an activeMathField subclass with a style object attribute
                            onClick={(evt: React.MouseEvent<SVGElement>) => {
                                moveCaretToSpecificIndex(entry[1].symbol)
                            }}
                            onMouseDown={(e) => {
                                e.preventDefault()
                            }}
                        />
                    </div>
                    )
                })}
                
           
        </div>
    }
    </div>
  )
}

export default MexInput