import {FaChevronRight,FaCopy} from "react-icons/fa6";
import {MathJax} from "better-react-mathjax";
import {useState } from "react";


export interface IUserSearch {
  query: string,
  date: Date,
}

const UserSearch = (props: IUserSearch) => {
  
  const [copyColor,setCopyColor]=useState<string>('')
  
  const copyQueryToClipBoard=() => {
    navigator.clipboard.writeText(props.query);
    setCopyColor('green')
    setTimeout(() => {
      setCopyColor('')
    },750)
  }
  

  return(
  <div className="w-full flex">
    <div className="main-container main-container__search"> 
      <div className={"flex items-center relative"}>
        <FaChevronRight size={"16px"} className={"m-2 block"}/>
        <div>
          <div className="you-searched flex flex-row justify-between">
            you searched: 
            <FaCopy
              style={{
                  marginLeft:'30px',
                  cursor:'pointer',
                  color:copyColor
                }}
              size={'1rem'}
              onClick={() => {
                copyQueryToClipBoard()
              }}
            />
          </div>
          <div className="search-query cursor-pointer"
          onClick={() => {
            copyQueryToClipBoard()
          }}
          >
            <MathJax>{props.query}</MathJax>
          </div>
        </div>
      </div>
    </div>
    <div className="side-container side-container__search">
      <div className="text-xs opacity-20">{props.date.toLocaleDateString()}</div>
      <div className="text-xs opacity-50  whitespace-nowrap">{props.date.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}</div>
    </div>
  </div>
  )
}


export default UserSearch;