import Header from "./Header";
import MathMexContext from "./MathMexContext";
import QueryBlock, {IQueryBlock} from "./QueryBlock";
import { useContext } from 'react'
import TempQueryBlock from "./TempQueryBlock";


export interface IResponseProps {
  queryBlocks: Array<IQueryBlock>
  setQueryBlocks: React.Dispatch<React.SetStateAction<IQueryBlock[]>>;
}

const ResponseContainer = () => {
  const context=useContext(MathMexContext)
   //using conditional logic I can set dont render, loading, and failed states for the next QueryBlock
  return(
    <div className="response-container-wrapper">
      <Header/>
      
      <div className="response-container">
        {!!context?.nextQueryBlockStatus && 
          <TempQueryBlock 
            status={context.nextQueryBlockStatus}
            query={context.queryRef.current?context.queryRef.current:''}
          />
        }
        {context?.queryBlocks.map(block => <QueryBlock
          search={block.search}
          responses={block.responses}
          showNextResult={block.showNextResult}
          key={block.id}
          id={block.id}
          numLoadedResults={block.numLoadedResults}
          />
        )}
      </div>
    </div>
  )
}

export default ResponseContainer