import React from 'react'
import { isMobile } from 'react-device-detect'
import { FaQuoteLeft,FaQuoteRight } from 'react-icons/fa6'
import { MathJax } from 'better-react-mathjax'
import { IMexResponseOutcome } from '../MexResponseOutcome'
import Feedback from '../Feedback'

function MexResponseText(props:IMexResponseOutcome) {
  return (
    <div className={isMobile?"mathmex-response--text__mobile":"mathmex-response--text"}>
          {isMobile?undefined:<FaQuoteLeft className={"mathmex-response--quote-left"}/>}
          {isMobile?
          <div className="flex flex-col">
            <MathJax inline={true} style={{width:'100%'}}>{props.body}</MathJax>
            <Feedback
              className="flex flex-row"
              feedback={props.feedback}
              clickThumbsUp={props.clickThumbsUp}
              clickThumbsDown={props.clickThumbsDown}
              style={{marginTop:'6px'}}
              rightStyle={{marginLeft:'12px'}}
            />
          </div>:
          <MathJax inline={true}>{props.body}</MathJax>}
          {isMobile?undefined:<FaQuoteRight className={"mathmex-response--quote-right"}/>}
    </div>
  )
}

export default MexResponseText