import {Tooltip} from "react-tooltip";
import {useContext, useEffect, useRef, useState} from "react";
import MathMexContext from "./MathMexContext";
import {isMobile} from 'react-device-detect';
import Feedback from "./Feedback";
import axios from "axios";
import { mathmex_api_baseurl } from "../App";
import MexResponseOutcome from "./MexResponseOutcome";

export interface IMexResponse {
  relevance: 'high' | 'medium' | 'low',
  link_href: string,
  title: string,
  body?: string,
  image_path?: string,
  feedback:number
  respIndex:number
  id?:string
}

const MexResponse = (props: IMexResponse) => {
  const context =useContext(MathMexContext)
  const [feedback, setFeedback] = useState(props.feedback)

  const feedbackFunction=(feedback:number)=>{
    axios.post(`${mathmex_api_baseurl}/feedback/`, {
      queryId:props.id,
      feedback:feedback,
      responseIndex:props.respIndex
    }).then(function (response) {
      console.log(response);
    })
    .catch(function (error) {
      console.log(error);
    })
    .finally(function () {
      // always executed
    });
  }

  const clickThumbsUp = () => {
    if (feedback === 1) {
      setFeedback(0)
      feedbackFunction(0)
    } else {
      setFeedback(1)
      feedbackFunction(1)
    }
  }

  const clickThumbsDown = () => {
    if (feedback === -1) {
      setFeedback(0)
      feedbackFunction(0)
    } else {
      setFeedback(-1)
      feedbackFunction(-1)
    }
  }

  useEffect(() => {
    context?.queryBlocks.forEach(block=>{
        if(props.id && block.id===props.id){
          block.responses.at(props.respIndex)!.feedback=feedback
        }
      
    })

  },[feedback])

  return (<div className="content-wrapper">
    <div className="main-container main-container__response">
      
      <div className={isMobile?"mathmex-response__mobile":"mathmex-response"}>
       
        <Tooltip id="relevance" style={{fontSize: '12px'}} />
        <div className={`mathmex-response--relevance mathmex-response--relevance__${props.relevance}`}
             data-tooltip-id="relevance"
             data-tooltip-content="Result Relevance"
             data-tooltip-place="top"
        >{props.relevance}</div>

        <div className="mathmex-response--title">{props.title}</div>
        <a
          href={props.link_href}
          className={"mathmex-response--link text-ellipsis"}
          target="_blank"
          rel="noopener noreferrer"
        >
          {props.link_href}
        </a>
        <MexResponseOutcome
          body={props.body}
          image_path={props.image_path}
          link_href={props.link_href}
          feedback={feedback}
          clickThumbsUp={clickThumbsUp}
          clickThumbsDown={clickThumbsDown}
        />
      </div>
    </div>
    {isMobile?undefined:
      <div className="side-container">
      <Feedback 
                className="flex justify-end flex-col"
                feedback={feedback}
                clickThumbsUp={clickThumbsUp}
                clickThumbsDown={clickThumbsDown}
              />
      </div>
    }
  </div>)
}

export default MexResponse;