import Logo from "./Logo";
import MathMexContext from "./MathMexContext";
import SourceAction from "./SourceAction";
import React, {useEffect, useState,useContext} from "react";

export interface ISettingsValues{
  youtube:boolean,
  arxiv:boolean,
  wikipedia:boolean,
  images:boolean,
  math_stack_exchange:boolean,
}

interface ISettingsProps{
    settingsRef:React.RefObject<ISettingsValues>
}

export const Settings = () => {
  const context=useContext(MathMexContext)

  const [sourceArXiv, setSourceArXiv] = useState<boolean>(!!context?.settingsRef.current?.arxiv);
  const [sourceMSE, setSourceMSE] = useState<boolean>(!!context?.settingsRef.current?.math_stack_exchange);
  const [sourceWikipedia, setSourceWikipedia] = useState<boolean>(!!context?.settingsRef.current?.wikipedia);
  const [sourceYouTube, setSourceYouTube] = useState<boolean>(!!context?.settingsRef.current?.youtube);
  const [sourceImages, setSourceImages] = useState<boolean>(!!context?.settingsRef.current?.images);


  const sourcesArray:boolean[]=[sourceArXiv,sourceMSE,sourceWikipedia,sourceYouTube,sourceImages]
  // little function to ensure the user has atleast 1 source selected or else the backend would break
  // this likely needs to be accounted for on the backend along with all other parts the user could potentially break
  const ensureOneSelected=(source?:boolean)=>{
    var unselectedSources:number=0

    sourcesArray.forEach((source) => {
      if(!source){
        unselectedSources+=1
      }
    })

    if(unselectedSources+1===sourcesArray.length && source){
      return false
    }
    return true
  }

  useEffect(() => {
    context!.settingsRef.current!.youtube=sourceYouTube
  },[sourceYouTube])

  useEffect(() => {
    context!.settingsRef.current!.arxiv=sourceArXiv
  },[sourceArXiv])

  useEffect(() => {
    context!.settingsRef.current!.wikipedia=sourceWikipedia
  },[sourceWikipedia])

  useEffect(() => {
    context!.settingsRef.current!.images=sourceImages
  },[sourceImages])

  useEffect(() => {
    context!.settingsRef.current!.math_stack_exchange=sourceMSE
  },[sourceMSE])

  return( 
  <div>
    <div className={"main-container settings-container"}>
      <div className={"text-4xl font-bold pb-4"}>MathMex Settings</div>
      <div className={"text-sm uppercase py-4 opacity-75"}>Retrieval Sources</div>
      <div className={"flex w-full flex-wrap gap-4 justify-center"}>
        <SourceAction active={sourceArXiv} setActive={setSourceArXiv} ensureOneSelected={ensureOneSelected} title={"ArXiv"} icon_url={"/source-logo/arxiv-logomark.svg"} />
        <SourceAction active={sourceMSE} setActive={setSourceMSE} ensureOneSelected={ensureOneSelected} title={"Stack Exchange"} icon_url={"/source-logo/cqa-logomark.svg"} />
        <SourceAction active={sourceWikipedia} setActive={setSourceWikipedia} ensureOneSelected={ensureOneSelected}  title={"Wikipedia"} icon_url={"/source-logo/wikipedia-logomark.svg"} />
        <SourceAction active={sourceYouTube} setActive={setSourceYouTube} ensureOneSelected={ensureOneSelected}  title={"YouTube"} icon_url={"/source-logo/youtube-logomark.svg"} />
        <SourceAction active={sourceImages} setActive={setSourceImages} ensureOneSelected={ensureOneSelected}  title={"Math Image Dataset"} icon_url={"/source-logo/image-logomark.svg"} />
      </div>
    </div>
    {/*<div className={"text-sm uppercase py-4 opacity-75"}>THEME</div>*/}
  </div>
  )
}

