import {FaMagnifyingGlass} from "react-icons/fa6";
import React, {useState,useRef, useContext} from "react";

import "mathlive";
import {MathfieldElement} from "mathlive";

import MexInput from "./MexInput";

import { IQueryBlock } from "./QueryBlock";
import Logo from "./Logo";
import MathMexContext,{IMathMexContext} from "./MathMexContext";
import { mathmex_api_baseurl } from "../App";
import axios from "axios";

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'math-field': React.DetailedHTMLProps<React.HTMLAttributes<MathfieldElement>, MathfieldElement>;
    }
  }
}

interface IHeader {
  queryBlocks:Array<IQueryBlock>
  setQueryBlocks: React.Dispatch<React.SetStateAction<Array<IQueryBlock>>>;
}

export const queryFunction=(query:string,context:IMathMexContext)=>{

  // Set Loading status
  context?.setNextQueryBlockStatus(1)
  context.queryRef!.current=query
  const params:any={
    query: query
  }
  if(context.settingsRef.current){
    Object.entries(context.settingsRef.current).forEach((source) => {
      if (!source[1]){
        params[source[0]]=source[1]
      }
    })
  }

  axios.get(`${mathmex_api_baseurl}/search/`, {
    params:params
  }).then(function (response) {
    console.log(response);
    response.data.search={
      query:query,
      date: new Date(),
    }
    const newBlock:IQueryBlock=response.data
    context?.setQueryBlocks([newBlock,...context.queryBlocks])
  })
  .catch(function (error) {
    // Failure status
    context?.setNextQueryBlockStatus(2)
    console.log(error);
  })
  .finally(function () {
    // always executed
  });
}

const Header = () => {
  const context =useContext(MathMexContext)
  return <div className="header-bar">
    <Logo/>
    <MexInput queryFunction={queryFunction}/>

  </div>
}

export default Header;