import React from 'react'
import { isMobile } from 'react-device-detect'
import { RxCornerTopLeft,RxCornerBottomRight } from "react-icons/rx";
import { MathJax } from 'better-react-mathjax'
import { IMexResponseOutcome } from '../MexResponseOutcome'
import Feedback from '../Feedback'
import { mathmex_api_baseurl } from '../../App';

function MexResponseImage(props:IMexResponseOutcome) {
  return (
    <div className={isMobile?"mathmex-response--text__mobile":"mathmex-response--text"}>
          {isMobile?undefined:<RxCornerTopLeft className={"mathmex-response--quote-left"}/>}
          {isMobile?
          <div className="flex flex-col">
            <div className={'mathmex-response--image-container'}>
              <img src={`${mathmex_api_baseurl}/media/${props.image_path}`}>
              </img>
            </div>
            <Feedback
              className="flex flex-row"
              feedback={props.feedback}
              clickThumbsUp={props.clickThumbsUp}
              clickThumbsDown={props.clickThumbsDown}
              style={{marginTop:'6px'}}
              rightStyle={{marginLeft:'12px'}}
            />
          </div>:
          <div className={'mathmex-response--image-container'}>
            <img src={`${mathmex_api_baseurl}/media/${props.image_path}`}>
            </img>
          </div>}
          {isMobile?undefined:<RxCornerBottomRight className={"mathmex-response--quote-right"}/>}
    </div>
  )
}

export default MexResponseImage