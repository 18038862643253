import './App.scss';

import FooterBar from "./components/FooterBar";
import {useEffect, useRef, useState} from "react";
import ResponseContainer from "./components/ResponseContainer";
import { IQueryBlock } from "./components/QueryBlock";
import { IMexInputData } from './components/MexInput';
import {Settings,ISettingsValues} from "./components/Settings";
import SettingsGear from './components/SettingsGear';
import {MathJaxContext} from "better-react-mathjax";
import MathMexContext from './components/MathMexContext';

const dummyQueryBlocks: Array<IQueryBlock> = [
  {
    search: {
      query: "\\(x^2 + 1 = 0\\) test test test test test test test test test test really long test!",
      date: new Date(),
    },
    responses: [
      {
        title: "Quadratic Equation",
        relevance: 'high',
        link_href: "https://math.stackexchange.com/questions/601436",
        body: "One line",
        feedback:0,
        respIndex:0,
      },
    ],
    showNextResult: true,
    id:'2',
    numLoadedResults:0
  },
  {
    search: {
      query: "\\(x^2 + 1 = 0\\)",
      date: new Date(),
    },
    responses: [
      {
        title: "Quadratic Equation",
        relevance: 'medium',
        link_href: "https://math.stackexchange.com/questions/601436",
        body: "I think it is best described with a quadratic equation with no solutions. We have the equation \\(y=x^2+1\\). We want to find the zeros (where such parabola intersects the x-axis).",
        feedback:0,
        respIndex:0,
      },
      {
        title: "Discontinuities Of Rational Functions | Mathematics Iii | High School Math | Khan Academy",
        relevance: 'low',
        link_href: "https://www.youtube.com/watch?v=qoMimDT7D8w#t=364.706s",
        body: "Well, something that makes the numerator zero without doing it to the denominator is going to be a zero. And x equals negative eight makes the numerator equal to zero without doing anything to, without making the denominator equal to zero.",
        feedback:0,
        respIndex:1
      },
    ],
    showNextResult: true,
    id:'1',
    numLoadedResults:0
  },
  {
    search: {
      query: "Pythagorean Theorem",
      date: new Date(1020050505003),   
    },
    responses: [
      {
        title: "Pythagorean Theorem",
        relevance: 'medium',
        link_href: "https://math.stackexchange.com/questions/1854184",
        body: "Use the Pythagorean theorem. It states that the square of the hypotenuse (the side opposite the right angle) is equal to the sum of the squares of the other two sides.",
        feedback:0,
        respIndex:0
      },
    ],
    showNextResult: false,
    id:'0',
    numLoadedResults:0
  }
];

const mathjax_config = {
  loader: { load: ["[tex]/html"] },
  tex: {
    packages: { "[+]": ["html"] },
    inlineMath: [
      ["$", "$"],
      ["\\(", "\\)"]
    ],
    displayMath: [
      ["$$", "$$"],
      ["\\[", "\\]"]
    ]
  }
};

export const mathmex_api_baseurl='https://api.mathmex.com'

export default function App() {
  const [inSettings, setInSettings] = useState<boolean>(false);
  const [queryBlocks, setQueryBlocks] = useState<Array<IQueryBlock>>([]);
  const [nextQueryBlockStatus,setNextQueryBlockStatus]=useState<number>(0)

  const settingsRef=useRef<ISettingsValues>({
    youtube:true,
    wikipedia:true,
    math_stack_exchange:true,
    images:true,
    arxiv:true})

  const mexInputData=useRef<IMexInputData>({
    indexableMathFieldValues:{},
    currentMathFieldIndex:0,
    mexInputInnerHtml:'',})
  
  const queryRef=useRef<string>()

  useEffect(() => {
    setNextQueryBlockStatus(0)
  },[queryBlocks])

  return (
    <MathJaxContext config={mathjax_config}>
      <MathMexContext.Provider value={{
        inSettings:inSettings,
        setInSettings:setInSettings,
        queryBlocks: queryBlocks,
        setQueryBlocks:setQueryBlocks,
        settingsRef:settingsRef,
        mexInputData:mexInputData,
        nextQueryBlockStatus:nextQueryBlockStatus,
        setNextQueryBlockStatus:setNextQueryBlockStatus,
        queryRef:queryRef
      }}>
      <div className="body-wrapper">
        <div className="centered-wrapper">
          <SettingsGear/>
          {inSettings ? <Settings/> : <ResponseContainer/>}
        </div>
        <FooterBar />
      </div>
      </MathMexContext.Provider>
    </MathJaxContext> 
    )
}