import React from "react";

import { IQueryBlock } from "./QueryBlock";
import {ISettingsValues} from "./Settings";
import { IMexInputData } from "./MexInput";

export interface IMathMexContext{
    inSettings:boolean,
    setInSettings:React.Dispatch<React.SetStateAction<boolean>>,
    queryBlocks: Array<IQueryBlock>,
    setQueryBlocks:React.Dispatch<React.SetStateAction<IQueryBlock[]>>,
    settingsRef:React.RefObject<ISettingsValues>,
    mexInputData:React.RefObject<IMexInputData>,
    nextQueryBlockStatus:number,
    setNextQueryBlockStatus:React.Dispatch<React.SetStateAction<number>>,
    queryRef:React.MutableRefObject<string | undefined>,
}

const MathMexContext = React.createContext<IMathMexContext|null>(null);

export default MathMexContext