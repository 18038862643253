import React from 'react'

import MexResponseImage from './MexResponseOutcomes/MexResponseImage'
import MexResponseText from './MexResponseOutcomes/MexResponseText'
import MexResponseYoutube from './MexResponseOutcomes/MexResponseYoutube'

export interface IMexResponseOutcome{
    body?:string,
    image_path?:string
    link_href:string,
    feedback:number,
    clickThumbsUp:()=>void
    clickThumbsDown:()=>void
}

function MexResponseOutcome(props:IMexResponseOutcome) {
  if(props.image_path){
    return(
      <MexResponseImage
      image_path={props.image_path}
      link_href={props.link_href}
      feedback={props.feedback}
      clickThumbsUp={props.clickThumbsUp}
      clickThumbsDown={props.clickThumbsDown}
      />
    )
  }
  else if(new URL(props.link_href).hostname=="www.youtube.com"){
    return(
      <MexResponseYoutube
      body={props.body}
      link_href={props.link_href}
      feedback={props.feedback}
      clickThumbsUp={props.clickThumbsUp}
      clickThumbsDown={props.clickThumbsDown}
      />
    )
  }
  return (
    <MexResponseText
    body={props.body}
    link_href={props.link_href}
    feedback={props.feedback}
    clickThumbsUp={props.clickThumbsUp}
    clickThumbsDown={props.clickThumbsDown}
    />
  )
}

export default MexResponseOutcome