import React from 'react'
import { isMobile } from 'react-device-detect'
import { MathJax } from 'better-react-mathjax'
import { IMexResponseOutcome } from '../MexResponseOutcome'
import Feedback from '../Feedback'

function MexResponseYoutube(props:IMexResponseOutcome) {
  
  const createYoutubeEmbedUrl=(link:string)=>{
    const timeRegEx = new RegExp(/#t=(.*)\./gm)
    const url = new URL(link);
    const params=url.searchParams
    return `https://www.youtube.com/embed/${params.get('v')}?start=${timeRegEx.exec(link)?.at(1)}`
  }

  const iframeheight:number=250
  return (
    <div className={isMobile?"mathmex-response--text__mobile":"mathmex-response--text"}>
          
          <div className="flex flex-col">
            <MathJax inline={true} style={{width:'100%',paddingBottom:'8px'}}>{props.body}</MathJax>
            <iframe 
              width="100%" 
              height={iframeheight}
              src={createYoutubeEmbedUrl(props.link_href)}>
            </iframe> 
            {isMobile?
            <Feedback
              className="flex flex-row"
              feedback={props.feedback}
              clickThumbsUp={props.clickThumbsUp}
              clickThumbsDown={props.clickThumbsDown}
              style={{marginTop:'6px'}}
              rightStyle={{marginLeft:'12px'}}
            />:undefined}
          </div>
          
        
    </div>
  )
}

export default MexResponseYoutube