import React from "react";

const FooterBar = () => {
  return <div className="bottom-bar">
    <a className="footer-option"
       href="https://link.springer.com/chapter/10.1007/978-3-031-56069-9_17"
    >
      About Us
    </a>
    •
    <a className="footer-option"
       href="https://forms.gle/uGwcof6px6gW5qHQ6"
       target="_blank"
       rel="noreferrer"
    >
      Send Feedback
    </a>
    •
    <a className="footer-option"
       href="https://cs.usm.maine.edu/~behrooz.mansouri/mathmex/MathMex_Demo.mp4"
       target="_blank"
       rel="noreferrer"
    >
      Demo Video
    </a>
    •
    <a className="footer-option"
       href="https://gitlab.com/Aiirlab/mathmex"
       target="_blank"
       rel="noreferrer"
    >
      Our GitLab
    </a>
    •
    <a className="footer-copyright cursor-pointer"
        href="https://cs.usm.maine.edu/~behrooz.mansouri/AIIRLab/"
    >
      2024 AIIRLab
    </a>
  </div>
}

export default FooterBar;